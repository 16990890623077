<template>
  <div style="overflow: hidden">
    <!-- NAME[epic=打印] 打印组件 -->
    <!-- <div class="design">
      <div
        v-if="is_error"
        role="alert"
        class="el-alert el-alert--error is-light"
      >
        <i class="el-alert__icon el-icon-error is-big"></i>
        <div class="el-alert__content">
          <span class="el-alert__title is-bold">
            创建插件失败，当前浏览器不支持插件，或插件在当前电脑上没有安装！
          </span>
          <p class="el-alert__description">
            特别提示：
            <a :href="design.gr_plugin_setup_url" target="_blank">
              点击下载WEB报表插件安装程序
            </a>
            ，下载后双击下载的文件进行安装，安装完成后重新打开当前网页。
          </p>
          <p class="el-alert__description">
            请参考:
            <a :href="design.gr_plugin_required_url" target="_blank">
              浏览器插件兼容性说明
            </a>
            ，选用支持插件的浏览器查看当前网页。
          </p>
          <i class="el-alert__closebtn el-icon-close"></i>
        </div>
      </div>
      <template v-if="design.typeid == 'classid'">
        <object
          id="ReportDesignerclassid"
          :classid="design.typeid_value"
          width="100%"
          height="100%"
        >
          <param name="SerialNo" :value="design.gr_SerialNo" />
          <param name="UserName" :value="design.gr_UserName" />
        </object>
      </template>
      <template v-else>
        <object
          id="ReportDesignertype"
          :type="design.typeid_value"
          width="100%"
          height="100%"
        >
          <param name="SerialNo" :value="design.gr_SerialNo" />
          <param name="UserName" :value="design.gr_UserName" />
        </object>
      </template>
    </div> -->
  </div>
</template>

<script>
  import { baseURL } from '@/config'
  import { getAccessToken } from '@/utils/accessToken'
  import { mapGetters } from 'vuex'
  // 单据 /ruilangDisplay?bill_type=XD&data_id=1182
  // 报表 /ruilangDisplay?bill_type=XD&type=2&cust_id=xx&params={xx:'aa',bb:'cc'}
  // import { httpAction, postAction, getAction } from '@/api/Employee'

  // var gr_InstallPath = 'grinstall',
  //   gr_Version = '6,7,20,1101'
  // //报表插件目前只能在32位浏览器中使用
  // var _gr_platform = window.navigator.platform,
  //   _gr_isX64 = _gr_platform.indexOf('64') > 0,
  //   _gr_agent = navigator.userAgent.toLowerCase(),
  //   _gr_isIE = _gr_agent.indexOf('msie') > 0,
  //   gr_CodeBase = _gr_isIE
  //     ? 'codebase="' +
  //       gr_InstallPath +
  //       (_gr_isX64 ? '/grbsctl6x64.cab' : '/grbsctl6.cab') +
  //       '#Version=' +
  //       gr_Version +
  //       '"'
  //     : '' //区分浏览器(IE or not)

  export default {
    name: 'RuilangDisplayDialog',
    props: {
      type: {
        type: Number,
        default: () => 1,
      },
      design_type: {
        type: String,
        default: () => {
          'displayviewer'
        },
      },
      bill_type: {
        type: String,
        default: () => {
          'XD'
        },
      },
      data_id: {
        type: [Number, String],
        default: () => {
          0
        },
      },
      cust_id: {
        type: Number,
        default: () => {
          0
        },
      },
      params: {
        type: Object,
        default: () => {
          return {}
        },
      },
      // 销售汇总参数
      summaryParams: {
        type: Object,
        default: () => {
          return {}
        },
      },
      // 盘点要用
      price_type: {
        type: [Number, String],
        default: () => {
          0
        },
      },
      // 自定义参数
      newObj: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },

    data: function () {
      return {
        dialogFormVisible: false,
        tpl_params: { bill_type: '', type: 0, data_id: 0 }, // 获取模板条件
        data_params: {}, // 模板数据源条件
        batch: false, // 是否批量打印
        design: {
          typeid: '',
          typeid_value: '',
          //displayviewer 创建报表查询显示插件，详细请查看帮助中的 IGRDisplayViewer
          //printviewer 创建报表打印显示插件，详细请查看帮助中的 IGRPrintViewer
          //designer 创建报表设计器插件，详细请查看帮助中的 IGRDesigner
          type: 'displayviewer', //"printviewer" "displayviewer",
          report: '',
          data: {},
          PrintTplViewUrl: 'setAdmin/print/print-tpl-view',
          gr_UserName: '锐浪报表插件本机开发测试注册',
          gr_SerialNo:
            '8PJH495VA61FLI5TG0L4KB2337F1G7AKLD6LNNA9F9T28IKRU6N33P8Z6XX4BUYB5E9NZ6INMD5T8EN47IX63VV7F9BJHB5ZJQQ6MX3J3V12C4XDHU97SXX6X3VA57KCB6',
          gr_plugin_setup_url: 'http://www.rubylong.cn/download/grbsctl6.exe',
          gr_plugin_required_url:
            'http://www.rubylong.cn/gridreport/doc/plugins_browser.htm',
        },
        is_error: false,
        designer: '',
      }
    },
    computed: {
      // 用户名
      ...mapGetters({
        accessToken: 'user/accessToken',
      }),
    },
    watch: {
      dialogFormVisible(val) {
        console.log('123', this.summaryParams)
        if (val) {
          if (this.dialogFormVisible) {
            if (this.batch) {
              // 批量打印
              window.open(
                `${baseURL}/PrintViewer2.htm?data_id=${
                  this.data_id
                }&bill_type=${this.bill_type}&token=${getAccessToken()}&type=${
                  this.type
                }`
              )
              this.dialogFormVisible = false
            } else {
              if (
                this.bill_type == 'LSP' ||
                this.bill_type == 'LYW' ||
                this.bill_type == 'LKH' ||
                this.bill_type == 'LHZ'
              ) {
                // 销售汇总用 data_id=1
                console.log('123456', this.summaryParams)
                const params = JSON.stringify(this.summaryParams)
                window.open(
                  `${baseURL}/PrintViewer.htm?data_id=${
                    this.data_id
                  }&bill_type=${
                    this.bill_type
                  }&token=${getAccessToken()}&type=${
                    this.type
                  }&sale_summary_param=${params}`
                )
                this.dialogFormVisible = false
              } else {
                let url_ = `${baseURL}/PrintViewer.htm?bill_type=${
                  this.bill_type
                }&token=${getAccessToken()}&type=${this.type}`
                if (this.data_id) {
                  url_ += `&data_id=${this.data_id}`
                }
                if (this.price_type) {
                  url_ +=
                    '&sale_summary_param={"price_type":"' +
                    this.price_type +
                    '"}'
                } else {
                  url_ += '&sale_summary_param=' + JSON.stringify(this.newObj)
                }
                // let o = ''
                // Object.keys(this.newObj).forEach((key) => {
                //   o += '&' + key + '=' + this.newObj[key]
                // })
                window.open(url_)
                this.dialogFormVisible = false
              }
            }
          }
        }
      },
    },
    created() {},
    mounted() {
      // if (_gr_isIE) {
      //   let key = ''
      //   if (isdesigner) {
      //     key = 'CE666189-5D7C-42ee-AAA4-E5CB375ED3C7'
      //   } else {
      //     if (this.design.type == 'printviewer') {
      //       key = 'ABB64AAC-D7E8-4733-B052-1B141C92F3CE'
      //     } else {
      //       key = '600CD6D9-EBE1-42cb-B8DF-DFB81977122E'
      //     }
      //   }
      //   this.design.typeid = 'classid'
      //   this.design.typeid_value = 'clsid:' + key + gr_CodeBase
      // } else {
      //   this.design.typeid = 'type'
      //   this.design.typeid_value = 'application/x-grplugin6-' + this.design.type
      // }
    },
    methods: {
      // open() {
      //   if (this.bill_type) {
      //     this.tpl_params.bill_type = this.bill_type
      //   }
      //   if (this.type) {
      //     this.tpl_params.type = this.type
      //   }
      //   if (this.data_id) {
      //     this.tpl_params.data_id = this.data_id
      //   }
      //   if (this.cust_id) {
      //     this.tpl_params.cust_id = this.cust_id
      //   }
      //   if (this.design_type) {
      //     this.design.type = this.design_type
      //   }
      //   if (this.type == 1) {
      //     this.data_params.data_id = this.tpl_params.data_id
      //   } else if (this.hasOwnProperty('params')) {
      //     this.data_params = this.query.params
      //   }
      //   console.log('init', this.tpl_params, this.data_params, this.design)
      //   // this.init()
      // },
      // close() {
      //   this.tpl_params = { bill_type: '', type: 0, data_id: 0 } // 获取模板条件
      //   this.data_params = {} // 模板数据源条件
      // },
      // init() {
      //   // 取模板
      //   postAction(this.design.PrintTplViewUrl, this.tpl_params)
      //     .then((res) => {
      //       console.log(res, '获取成功')
      //       this.design.report = res.data.tpl_data
      //       // 加载报表插件
      //       this.DisplayViewerInit()
      //     })
      //     .catch((err) => {
      //       console.log(err, '')
      //       this.$message({
      //         message: err.msg,
      //         type: 'warning',
      //       })
      //       return false
      //     })
      // },
      /**
       * 在报表执行每个任务动作前触发本事件，任务动作对应DoAction方法所能执行的任务
       * @GRDesignerAction Action
       */
      // BeforeDoAction(Action) {
      //   console.log('动作，可控制权限', Action)
      //   if (Action == 409) {
      //     // this.designer.DefaultAction = false; //屏蔽控件本身的行为
      //   }
      // },
      // ToolbarCommandClick(ControlType) {
      //   console.log(ControlType, '====')
      // },
      // /**
      //  * 创建报表查询显示插件
      //  */
      // DisplayViewerInit() {
      //   let ds = document.getElementById('ReportDesigner' + this.design.typeid)
      //   console.log('ReportDesigner')
      //   console.log(this.design.typeid)
      //   console.log(ds.Report)
      //   if (!ds.Report) {
      //     this.is_error = true
      //     alert(
      //       '创建插件失败，当前浏览器不支持插件，或插件在当前电脑上没有安装！'
      //     )
      //     return false
      //   }
      //   this.designer = ds
      //   this.designer.Report.LoadFromStr(this.design.report)
      //   this.designer.ToolbarCommandClick = function (ControlType) {
      //     this.ToolbarCommandClick(ControlType)
      //   }
      //   //处理打印相关事件
      //   //关联报表事件
      //   this.designer.Report.OnPrintBegin = this.OnPrintBegin()
      //   this.designer.Report.OnPrintEnd = this.OnPrintEnd()
      //   this.designer.Report.OnPrintAborted = this.OnPrintAborted()
      //   this.designer.Report.OnPrintPage = this.OnPrintPage()
      //   // 载入数据
      //   if (!this.designer.Report.DataLoaded) {
      //     let that = this
      //     var QuerySQL = this.designer.Report.DetailGrid
      //       ? this.designer.Report.DetailGrid.Recordset.QuerySQL
      //       : this.designer.Report.QuerySQL
      //     console.log(QuerySQL, '数据源')
      //     // 根据数据源加载数据
      //     getAction(QuerySQL, this.data_params)
      //       .then((res) => {
      //         if (typeof res == 'object') {
      //           res = JSON.stringify(res)
      //         }
      //         this.design.data = res
      //         // 从 XML 或 JSON 文字串中载入报表明细记录集数据，数据应符合约定的形式
      //         console.log(this.design.data, '数据源获取成功')
      //         this.designer.Report.LoadDataFromXML(this.design.data)
      //         this.designer.Start()
      //         // this.design.report = undefined
      //         // this.design.data = undefined
      //       })
      //       .catch((err) => {
      //         console.log(err, '')
      //         this.$message({
      //           message: err.msg,
      //           type: 'warning',
      //         })
      //         return false
      //       })
      //   }
      // },
      // PrintAborted 当打印任务中途取消时触发本事件。
      // PrintBegin 在开始打印报表之前，触发本事件。
      // PrintEnd 在结束打印报表之后，触发本事件。
      // PrintPage 在打印每一页报表页面时触发本事件。
      // OnPrintBegin() {},
      // OnPrintEnd() {},
      // OnPrintAborted() {},
      // OnPrintPage() {},
    },
  }
</script>

<style scoped>
  .design {
    width: 100%;
    height: calc(90vh - 20px * 2 - 55px);
    /*border: #0aaafa solid 1px;*/
  }
</style>
